var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import { mapState } from "vuex";
import { toMapboxLatLong, } from "../../../../utils/mapbox";
import gql from "graphql-tag";
import Pin from "./Pin.vue";
import ListingPopupContent from "./ListingPopupContent.vue";
import InstitutionMarker from "./InstitutionMarker.vue";
import institutionDisplayNameFragment from "../../../../utils/institutionDisplayNameFragment.graphql";
import { displayName } from "../../../../utils/institution";
export default Vue.extend({
    components: {
        Pin: Pin,
        InstitutionMarker: InstitutionMarker,
        ListingPopupContent: ListingPopupContent,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        showZoomControls: {
            type: Boolean,
            default: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment AreaMap_property on PropertyListing {\n        id\n        latLong {\n          lat\n          long\n        }\n        institutionTags {\n          distanceTo\n          institution {\n            id\n            ...InstitutionMarker_institution\n            ...InstitutionDisplayName_institution\n            latLong {\n              lat\n              long\n            }\n          }\n        }\n        ...ListingPopupContent_property\n      }\n      ", "\n      ", "\n      ", "\n    "], ["\n      fragment AreaMap_property on PropertyListing {\n        id\n        latLong {\n          lat\n          long\n        }\n        institutionTags {\n          distanceTo\n          institution {\n            id\n            ...InstitutionMarker_institution\n            ...InstitutionDisplayName_institution\n            latLong {\n              lat\n              long\n            }\n          }\n        }\n        ...ListingPopupContent_property\n      }\n      ", "\n      ", "\n      ", "\n    "])), ListingPopupContent.options.fragments.property, InstitutionMarker.options.fragments.institution, institutionDisplayNameFragment),
    },
    data: function () {
        return {
            focusedInstitutionTag: undefined,
        };
    },
    computed: __assign({ mapboxLatLong: function () {
            return toMapboxLatLong(this.property.latLong);
        } }, mapState(["mapboxAccessToken"])),
    methods: {
        onMapInstitutionChange: function () {
            if (!this.focusedInstitutionTag ||
                !this.$refs.mglMap.map) {
                return;
            }
            var bounds = __spreadArray([
                toMapboxLatLong(this.focusedInstitutionTag.institution.latLong)
            ], (this.mapboxLatLong ? [this.mapboxLatLong] : []), true);
            this.$refs.mglMap.map.fitBounds(bounds, { padding: 50 });
        },
        getInstitutionTagLabel: function (tag) {
            return displayName(tag.institution);
        },
    },
});
var templateObject_1;
