var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import toPairs from "lodash/toPairs";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import RoomTypeCard from "./RoomTypeCard.vue";
export default Vue.extend({
    components: {
        RoomTypeCard: RoomTypeCard,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            selectedIndex: 0,
        };
    },
    computed: {
        // hasMultipleLeaseTerms() {
        //   return (
        //     this.property.roomTypes.length > 1 ||
        //     (this.property.roomTypes.length === 1 &&
        //       this.property.roomTypes[0].leaseTerms.length > 1)
        //   );
        // },
        hasMultipleRoomTypes: function () {
            // 1 for "all", the other for th specific type
            return Object.keys(this.roomTypes).length > 2;
        },
        roomTypes: function () {
            var defaultOrdering = ["shared", "private", "entire"];
            var getPropertyTypeNameOrder = function (propertyTypeName) {
                var index = defaultOrdering.findIndex(function (o) {
                    return propertyTypeName.toLowerCase().includes(o);
                });
                if (index === -1) {
                    return Number.MAX_VALUE;
                }
                return index;
            };
            var all = [
                this.$t("All rooms").toString(),
                sortBy(this.property.roomTypes, function (r) {
                    return getPropertyTypeNameOrder(r.propertyTypeName);
                }),
            ];
            if (this.property.roomTypes.length === 0) {
                return [all];
            }
            var base = sortBy(toPairs(groupBy(this.property.roomTypes, function (r) { return r.propertyTypeName; })), function (_a) {
                var name = _a[0];
                return getPropertyTypeNameOrder(name);
            });
            return __spreadArray([all], base, true);
        },
        selectedRoomTypes: function () {
            return this.roomTypes[this.selectedIndex][1];
        },
    },
});
