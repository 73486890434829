var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"room-type-card"},[_c('div',{staticClass:"rtc-head"},[(_vm.roomType.photos.length > 0)?_c('button',{staticClass:"rtc-img flex flex-start flex-start-horizontal",attrs:{"type":"button"},on:{"click":function($event){return _vm.onPhotoClick()}}},[_c('div',{staticClass:"rtc-img-wrapper"},[_c('img',{attrs:{"src":_vm.roomType.photos[0].smallUrl,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"img-counts"},[_c('div',{staticClass:"img-count-icon"},[_c('img',{attrs:{"src":require('../../../images/photo.svg'),"alt":"photo icon"}}),_vm._v(" "),_c('div',{staticClass:"photo-badge"},[_vm._v("\n              "+_vm._s(_vm.roomType.photos.length)+"\n            ")])])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"rtc-content"},[_c('h5',[_vm._v(_vm._s(_vm.roomType.title))]),_vm._v(" "),_c('span',{staticClass:"small bold blue"},[_vm._v(_vm._s(_vm.roomType.propertyTypeName))]),_vm._v(" "),_c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function({ mobile }){return [_c('truncate',{staticClass:"product-read-more room-type-desc",attrs:{"clamp":_vm.$t('Read more'),"length":mobile ? 22 : 160,"type":"html","less":_vm.$t('Show less'),"text":_vm.roomType.description}})]}}])}),_vm._v(" "),_c('div',{staticClass:"lease-features flex flex-wrap"},_vm._l((_vm.icons),function(icon,i){return _c('div',{key:i,staticClass:"a-feature flex flex-ac"},[_c('img',{staticClass:"icon",attrs:{"src":icon.image,"alt":"house icon"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(icon.text))])])}),0)],1)]),_vm._v(" "),_c('div',{staticClass:"rtc-foot"},[_c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function({ mobile }){return [_vm._l((mobile && !_vm.showAllLeaseOptions
          ? _vm.roomType.leaseTerms.slice(0, 1)
          : _vm.roomType.leaseTerms),function(leaseTerm){return _c('div',{key:leaseTerm.id,staticClass:"rtc-lease flex flex-sb"},[_c('div',{staticClass:"lease-left"},[_c('span',[_vm._v(_vm._s(leaseTerm.moveInDateDescription))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(leaseTerm.leaseTerm))])]),_vm._v(" "),_c('div',{staticClass:"lease-end flex"},[_c('div',{staticClass:"lease-price-wrapper flex flex-ac"},[_c('span',{staticClass:"lease-price"},[_vm._v("\n              "+_vm._s(_vm._f("money")(leaseTerm.pricePerWeek))+"\n            ")]),_vm._v(" "),_c('span',[_vm._v("/ week")])]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-small btn-orange",attrs:{"to":_vm.property.isLeased
                ? '#'
                : `/accommodation/listing/${_vm.property.slug}/${leaseTerm.id}/enquire-now`}},[_vm._v("\n            "+_vm._s(_vm.$t("Enquire"))+"\n          ")])],1)])}),_vm._v(" "),(
          mobile && !_vm.showAllLeaseOptions && _vm.roomType.leaseTerms.length > 1
        )?_c('button',{staticClass:"btn btn-more-options btn-block",attrs:{"type":"button"},on:{"click":function($event){_vm.showAllLeaseOptions = true}}},[_vm._v("\n        "+_vm._s(_vm.$t("Show more lease options"))+"\n      ")]):_vm._e(),_vm._v(" "),(mobile && _vm.showAllLeaseOptions && _vm.roomType.leaseTerms.length > 1)?_c('button',{staticClass:"btn btn-more-options btn-block",attrs:{"type":"button"},on:{"click":function($event){_vm.showAllLeaseOptions = false}}},[_vm._v("\n        "+_vm._s(_vm.$t("Show less lease options"))+"\n      ")]):_vm._e()]}}])})],1),_vm._v(" "),(_vm.galleryShowIndex !== undefined)?_c('media-gallery-dialog',{attrs:{"media":_vm.media,"current-index":_vm.galleryShowIndex},on:{"close":_vm.onGalleryClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }