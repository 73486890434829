import Vue from "vue";
import AreaMap from "./AreaMap.vue";
export default Vue.extend({
    components: {
        AreaMap: AreaMap,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
});
