var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import PropertyCard from "../../public/accommodation/PropertyCard.vue";
import gql from "graphql-tag";
import SaveButton from "./SaveButton.vue";
export default Vue.extend({
    components: {
        PropertyCard: PropertyCard,
        SaveButton: SaveButton,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SimilarPropertyCard_property on PropertyListing {\n        id\n        ...PropertyCard_property\n        ...SaveButton_property\n      }\n      ", "\n      ", "\n    "], ["\n      fragment SimilarPropertyCard_property on PropertyListing {\n        id\n        ...PropertyCard_property\n        ...SaveButton_property\n      }\n      ", "\n      ", "\n    "])), PropertyCard.options.fragments.property, SaveButton.options.fragments.property),
    },
});
var templateObject_1;
