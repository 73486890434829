var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import gql from "graphql-tag";
import MediaGalleryDialog from "./MediaGalleryDialog.vue";
export default Vue.extend({
    components: {
        MediaGalleryDialog: MediaGalleryDialog,
    },
    props: {
        roomType: {
            type: Object,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        roomType: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment RoomTypeCard_roomType on RoomType {\n        id\n        propertyTypeName\n        title\n        description\n        roomIconText\n        showerIconText\n        bedIconText\n        kitchenIconText\n        roomSizeIconText\n        airconIconText\n        otherIconText\n        photos {\n          id\n          smallUrl\n          mediumUrl\n          largeUrl\n        }\n        leaseTerms {\n          id\n          moveInDateDescription\n          leaseTerm\n          pricePerWeek {\n            amount\n            currency\n          }\n        }\n      }\n    "], ["\n      fragment RoomTypeCard_roomType on RoomType {\n        id\n        propertyTypeName\n        title\n        description\n        roomIconText\n        showerIconText\n        bedIconText\n        kitchenIconText\n        roomSizeIconText\n        airconIconText\n        otherIconText\n        photos {\n          id\n          smallUrl\n          mediumUrl\n          largeUrl\n        }\n        leaseTerms {\n          id\n          moveInDateDescription\n          leaseTerm\n          pricePerWeek {\n            amount\n            currency\n          }\n        }\n      }\n    "]))),
    },
    data: function () {
        return {
            galleryShowIndex: undefined,
            showAllLeaseOptions: false,
        };
    },
    computed: {
        icons: function () {
            var all = [
                {
                    text: this.roomType.roomIconText,
                    image: require("../../../images/house-black.svg"),
                },
                {
                    text: this.roomType.showerIconText,
                    image: require("../../../images/bath-black.svg"),
                },
                {
                    text: this.roomType.bedIconText,
                    image: require("../../../images/bed-black.svg"),
                },
                {
                    text: this.roomType.kitchenIconText,
                    image: require("../../../images/microwave.svg"),
                },
                {
                    text: this.roomType.roomSizeIconText,
                    image: require("../../../images/area.svg"),
                },
                {
                    text: this.roomType.airconIconText,
                    image: require("../../../images/aircondition.svg"),
                },
                {
                    text: this.roomType.otherIconText,
                    image: require("../../../images/other.svg"),
                },
            ];
            return all.filter(function (i) { return !!i.text; });
        },
        media: function () {
            return this.roomType.photos.map(function (p) { return (__assign({ type: "photo" }, p)); });
        },
    },
    methods: {
        onGalleryClose: function () {
            this.galleryShowIndex = undefined;
        },
        onPhotoClick: function () {
            this.galleryShowIndex = 0;
        },
    },
});
var templateObject_1;
