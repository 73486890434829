import Vue from "vue";
export default Vue.extend({
    props: {
        color: {
            type: String,
            required: true,
        },
        scale: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width: function () {
            return this.scale * 36;
        },
        height: function () {
            return this.scale * 56;
        },
    },
});
