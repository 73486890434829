var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import SimilarPropertyCard from "./SimilarPropertyCard.vue";
export default Vue.extend({
    components: {
        SimilarPropertyCard: SimilarPropertyCard,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SimilarProperties_property on PropertyListing {\n        id\n        similarProperties(amount: 6) {\n          id\n          ...SimilarPropertyCard_property\n        }\n      }\n      ", "\n    "], ["\n      fragment SimilarProperties_property on PropertyListing {\n        id\n        similarProperties(amount: 6) {\n          id\n          ...SimilarPropertyCard_property\n        }\n      }\n      ", "\n    "])), SimilarPropertyCard.options.fragments.property),
    },
    data: function () {
        return {
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerColumn: 1,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    900: {
                        slidesPerView: 3,
                    },
                    500: {
                        slidesPerView: 2,
                    },
                },
            },
        };
    },
});
var templateObject_1;
