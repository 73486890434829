import Vue from "vue";
export default Vue.extend({
    props: {
        media: {
            type: Array,
            required: true,
        },
        currentIndex: {
            type: Number,
            default: undefined,
        },
    },
    data: function () {
        return {
            swiperOptions: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                initialSlide: this.currentIndex,
                centeredSlides: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
            swiperOptionsThumbs: {
                initialSlide: this.currentIndex,
                spaceBetween: 10,
                touchRatio: 0.2,
                slideToClickedSlide: true,
                centeredSlides: true,
                slidesPerView: "auto",
            },
        };
    },
    mounted: function () {
        var _this = this;
        var initSwipers = function () {
            var swiperRef = _this.$refs.swiper;
            var swiperThumbsRef = _this.$refs.swiperThumbs;
            if (!swiperRef || !swiperThumbsRef) {
                setTimeout(initSwipers, 1);
                return;
            }
            var swiper = swiperRef.swiperInstance;
            var swiperThumbs = swiperThumbsRef.swiperInstance;
            // Had issue in past with underlying api changing
            if (!swiper || !swiperThumbs) {
                throw new Error("Couldn't receive swiper instance");
            }
            swiper.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiper;
            swiper.update();
            swiperThumbs.update();
            swiper.slideTo(_this.currentIndex, 0, false);
            swiperThumbs.slideTo(_this.currentIndex, 0, false);
            swiper.off("slideChangeTransitionStart");
            swiper.on("slideChangeTransitionStart", function () {
                var slide = this.slides[this.activeIndex];
                var iFrame = slide.querySelector("iframe");
                if (!iFrame || !iFrame.contentWindow) {
                    return;
                }
                // Hacky way to reload iframe, to stop video playing
                iFrame.src += "";
            });
        };
        setTimeout(initSwipers, 100);
    },
});
