var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { mapState } from "vuex";
import { mapboxStaticMapUrl } from "../../../../utils/mapbox";
import AreaMapDialog from "./AreaMapDialog.vue";
export default Vue.extend({
    components: {
        AreaMapDialog: AreaMapDialog,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            isMapOpen: false,
        };
    },
    computed: __assign({ mapUrl: function () {
            return mapboxStaticMapUrl(this.mapboxAccessToken, this.property.latLong, {
                width: 500,
                height: 500,
                showPin: true,
            });
        } }, mapState(["mapboxAccessToken"])),
    methods: {
        onOpenMapClick: function () {
            this.isMapOpen = true;
        },
        onMapClose: function () {
            this.isMapOpen = false;
        },
    },
});
