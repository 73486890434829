var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import MediaGalleryDialog from "./MediaGalleryDialog.vue";
var showMoreLimit = 5;
export default Vue.extend({
    components: {
        MediaGalleryDialog: MediaGalleryDialog,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            galleryShowIndex: undefined,
        };
    },
    computed: {
        allMedia: function () {
            var photoMedia = this.property.photos.map(function (photo) { return (__assign({ type: "photo" }, photo)); });
            var videoMedia = this.property.videos.map(function (video) { return (__assign({ type: "video" }, video)); });
            if (photoMedia.length === 0) {
                return videoMedia;
            }
            return __spreadArray(__spreadArray([photoMedia[0]], videoMedia, true), photoMedia.slice(1), true);
        },
        numberOfHiddenMedia: function () {
            return this.allMedia.length - this.thumbnailsMedia.length - 1;
        },
        thumbnailsMedia: function () {
            return this.allMedia.slice(1, showMoreLimit);
        },
        mainImageUrl: function () {
            if (this.allMedia.length === 0) {
                return undefined;
            }
            return this.allMedia[0].largeBannerUrl;
        },
    },
    methods: {
        onMediaClick: function (index) {
            this.galleryShowIndex = index;
        },
        onGalleryClose: function () {
            this.galleryShowIndex = undefined;
        },
    },
});
